import React, {Component} from 'react';
import logo from '../logo.svg';
import '../App.css';

class webD0 extends Component {
    render() { 
        return (
            <div className="App">
                <header className="App-header">
                    <p className="div1">Apparently I know <a target="_blank" rel="noopener noreferrer" href='https://reactjs.org/' className="text-info">REACT</a> basics</p>
                    <img src={logo} className="App-logo div3" alt="logo" />
                    <p className="div2">Welcome to my Lab.</p>
                </header>
            </div>
        );
    }
}
 
export default webD0;