import React from 'react';
import { Link } from "react-router-dom";

const navBar = () => {
    return ( 
        <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-transparent">
            <Link to="/" className="navbar-brand text-light">Marcelo Vilela</Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="nav navbar-nav ml-auto">
                    {/* <li className="nav-item">
                        <Link to="/index-MyCV" className="nav-link text-light"> My CV </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/index-Math" className="nav-link text-light"> Math </Link>
                    </li> */}
                    <li className="nav-item">
                        <Link to="/index-Resources" className="nav-link text-light"> Resources </Link>
                    </li>
                </ul>
            </div>
        </nav>
     );
}

export default navBar;