import React, {Component} from 'react';
import '../App.css';

class webD0 extends Component {
    render() { 
        return (
            <div className="App">
                <header className="App-header">
                    <div className="div4">
                        <iframe title="My CV" src='https://cdn.knightlab.com/libs/timeline3/latest/embed/index.html?source=10XI9NYI3PuMtxh0188EKEWmiweHmUNRsdm-v2sT3Zco&start_at_end=true&font=Default&lang=en&initial_zoom=0' width="100%" height="100%" webkitallowfullscreen mozallowfullscreen allowfullscreen frameborder='0'></iframe>
                    </div>
                </header>
            </div>
        );
    }
}
 
export default webD0;