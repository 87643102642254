import React, {Component} from 'react';
import logo from '../logo.svg';
import '../App.css';

import { Link } from "react-router-dom";

class webD0 extends Component {
    render() { 
        return (
            <div className="App">
                <header className="App-header">
                    <p className="div1">This place is under construction.</p>
                    <Link to="/Resources" className="div3"><img src={logo} className="App-logo" alt="logo" /></Link>
                    <p className="div2">Welcome to my Lab.</p>
                </header>
            </div>
        );
    }
}
 
export default webD0;