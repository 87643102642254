import React from 'react';
import NavBar from './components/navbar'
import MyCV from './components/mycv'
// import Webd0 from './components/webd0'
// import Webd1 from './components/webd1'
import Webd2 from './components/webd2'

// import Webd0_1 from './components/webd0_1'
// import Webd1_1 from './components/webd1_1'
import Webd2_1 from './components/webd2_1'

import { Switch, withRouter, Route, BrowserRouter as Router } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

const AnimatedSwitch = withRouter(({ location }) => (
  <TransitionGroup>
    <CSSTransition key={location.key} classNames="slide" timeout={1000}>
      <Switch location={location}>
        <Route exact path="/" component={MyCV} />
        {/* <Route exact path="/index-MyCV" component={Webd0} />
        <Route exact path="/index-Math" component={Webd1} /> */}
        <Route exact path="/index-Resources" component={Webd2} />
      </Switch>
    </CSSTransition>
  </TransitionGroup>
));

const AnimatedSwitch2 = withRouter(({ location }) => (
  <TransitionGroup>
    <CSSTransition key={location.key} classNames="slide2" timeout={1000}>
      <Switch location={location}>
        {/* <Route exact path="/MyCV" component={Webd0_1} />
        <Route exact path="/Math" component={Webd1_1} /> */}
        <Route exact path="/Resources" component={Webd2_1} />
      </Switch>
    </CSSTransition>
  </TransitionGroup>
));

function App() {
  return (
    <React.Fragment>
      <Router>
        <NavBar />
        <AnimatedSwitch />
        <AnimatedSwitch2 />
      </Router>
    </React.Fragment>
  );
}

export default App;
